<template>
  <div>
    <v-container v-if="productInfo !== ''" class="pa-0">
      <Banner />
      <Info :listData="productInfo" />
      <template v-if="productInfo.name === null">
        <Form :qrCode="id" />
      </template>
      <template v-else>
        <info-customer :listData="productInfo" />
      </template>

      <v-dialog persistent v-model="popDialog" max-width="450">
        <!-- <v-card color="red" rounded>
          <v-card-title class="my-2 text-caption white--text">
            {{ popTitle }}
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card> -->

        <v-card>

        <v-card-text class="pt-4">
         {{ popTitle }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           color="red lighten-2"
          dark
          v-bind="attrs"
            @click="goBack"
          >
            Kembali
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
    </v-container>

    <v-container v-else fill-height>
      <v-row class="ma-3 justify-center">
        <v-progress-circular
          :size="50"
          color="green"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import Banner from "@/components/Banner.vue";
import Info from "@/components/Info.vue";
import Form from "@/components/Form.vue";
import InfoCustomer from "../components/InfoCustomer.vue";

export default {
  name: "Home",
  props: ["id"],
  components: {
    Banner,
    Info,
    Form,
    InfoCustomer,
  },
  metaInfo: {
    title: "Rhea Natural",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },

  data: () => ({
    productInfo: {},
    popDialog: false,
    popTitle: "",
  }),

  methods: {
    
    getInfo() {
      axios
        .get("v1/claim/info?qr_code=" + this.id)
        .then((response) => {
          if (response.status === 200) {
            this.productInfo = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.popDialog = true;
            if(error.response.data.errMessage==="qr code not found"){
               this.popTitle = "Mohon maaf kami tidak dapat menemukan kode QR tersebut.";
            }else{
                this.popTitle = error.response.data.errMessage;
            }
            
          }
        });
    },
    goBack(){
      this.popDialog = false;
      window.open("https://rhea.co.id/produk/rhea-health-tone","_self");
    },
  },

  mounted() {
    this.getInfo();
  },
};
</script>
