<template>
  <v-container class="pa-0">
    <v-card color="white" flat elevation="0" tile>
      <v-card-text class="pl-5 pt-5 pb-0">
        <p class="title">MANUFACTURING DATE:</p>
        <p class="subtitle">{{ listData.manufacturing_date | moment("DD MMMM YYYY") }}</p>
      </v-card-text>
      <v-card-text class="pl-5 pt-5 pb-0">
        <p class="title">BATCH NO:</p>
        <p class="subtitle">{{ listData.batch_no }}</p>
      </v-card-text>
      <v-card-text class="pl-5 pt-5 pb-0">
        <p class="title">EXPIRED DATE:</p>
        <p class="subtitle">{{ listData.expired_date | moment("DD MMMM YYYY") }}</p>
      </v-card-text>
      <v-card-text class="pl-5 pt-5 pb-0">
        <p class="title">CUSTOMER/DISTRIBUTOR:</p>
        <p class="subtitle">{{ listData.cust_name }}</p>
      </v-card-text>
      <v-card-text class="pl-5 pt-5 pb-10">
        <p class="title">DISTRIBUTE DATE</p>
        <p class="subtitle">{{ listData.distributed_date | moment("DD MMMM YYYY") }}</p>
      </v-card-text>
    </v-card>
    <v-card color="#004B44" flat elevation="0" tile>
      <v-card-text class="pt-5 pb-4 thxTo">
        <center>
          <p>Terimakasih sudah membeli</p>
          <p>produk RHEA</p>
        </center>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: { listData: Object },
};
</script>

<style scoped>
.title {
  color: #004b44;
  font-size: 15px !important;
  font-weight: 800;
  line-height: 6px;
  letter-spacing: 0.1em !important;
}
.subtitle {
  color: black;
  font-size: 15px;
  font-weight: normal;
  line-height: 6px;
}
.thxTo {
  color: #f1f1f1 !important;
  font-size: 18px;
  font-weight: bold;
  line-height: 10px;
}
</style>
