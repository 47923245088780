<template>
  <v-container class="pa-0">
    <v-card tile elevation="0">
      <v-img
        eager
        lazy-src="@/assets/background.png"
        src="@/assets/background.png"
        class="white--text"
        aspect-ratio="1.1"
      >
        <v-container class="background" fill-height>
          <v-form ref="form" v-model="valid">
            <v-card tile elevation="0" color="rgba(0, 0, 0, 0)">
              <v-card-text class="pa-0 px-3 pt-3 thxTo white--text">
                Isi data berikut:
              </v-card-text>
              <v-card-text class="pa-3">
                <v-text-field
                  v-model="userData.name"
                  solo
                  hide-details
                  outlined
                  placeholder="Nama"
                  :rules="[rules.required]"
                />
              </v-card-text>
              <v-card-text class="pa-3">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-left="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="userData.paid_date"
                      placeholder="Tanggal Beli"
                      readonly
                      solo
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    color="#61B15A"
                    no-title
                    :max="date"
                    v-model="userData.paid_date"
                    @input="dateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-card-text>
              <v-card-text class="pa-3">
                <v-text-field
                  v-model="userData.email"
                  solo
                  hide-details
                  outlined
                  placeholder="Email"
                  :rules="[rules.required, rules.email]"
                />
              </v-card-text>
              <v-card-text class="pa-3">
                <v-text-field
                  v-model="userData.no_telp"
                  solo
                  hide-details
                  outlined
                  placeholder="No. Telp"
                  :rules="[
                    rules.required,
                    rules.maxNoPonsel,
                    rules.minNoPonsel,
                  ]"
                  type="number"
                />
              </v-card-text>
              <v-card-text class="pa-3">
                <v-btn
                  block
                  color="#61B15A"
                  class="white--text"
                  @click="submit"
                  :disabled="!valid"
                >
                  SUBMIT
                </v-btn>
              </v-card-text>
            </v-card>
          </v-form>
        </v-container>
      </v-img>
    </v-card>

    <v-dialog v-model="confirm" persistent max-width="450">
      <v-card color="#004B44" class="rounded-lg">
        <v-card-title
          class="justify-center text-subtitle-2 font-weight-bold white--text"
        >
          Konfirmasi Klaim Reward
        </v-card-title>

        <v-card-subtitle class="pt-3 text-caption white--text">
          <center>
            Silahkan cek kembali data Anda. Data ini akan disimpan di server
            kami dan tidak dapat diubah.
          </center>
        </v-card-subtitle>

        <v-card-actions class="pb-5">
          <v-row class="px-2">
            <v-col class="px-2">
              <v-btn
                block
                @click="confirm = false"
                style="text-transform: unset !important"
                class="black--text"
              >
                Batalkan
              </v-btn>
            </v-col>
            <v-col class="px-2">
              <v-btn
                block
                @click="claim"
                style="text-transform: unset !important"
                class="black--text"
              >
                Klaim
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  props: { qrCode: String },
  data: () => ({
    valid: false,
    absolute: true,
    opacity: 0.6,
    confirm: false,
    dateMenu: false,
    date: new Date().toISOString().substr(0, 10),
    userData: {
      qr_code: "",
      name: "",
      paid_date: "",
      email: "",
      no_telp: "",
    },
    rules: {
      required: (value) => !!value || "Tidak boleh kosong !!",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "e-mail tidak valid !!";
      },
      maxNoPonsel: (value) => !!value && value.length <= 13,
      minNoPonsel: (value) => !!value && value.length >= 11,
    },
  }),
  methods: {
    submit() {
      this.confirm = true;
    },
    claim() {
      this.userData.qr_code = this.qrCode;
      this.userData.name = this.userData.name.replace(/\b\w/g, (c) =>
        c.toUpperCase()
      );
      this.userData.email = this.userData.email.toLowerCase();
      const data = JSON.stringify(this.userData);
      axios
        .post("v1/claim/user", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.confirm = false;
            this.refreshPage();
          }
        });
    },
    refreshPage() {
      this.$router.go(this.$router.currentRoute);
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
  },
};
</script>

<style scoped>
.background {
  background: rgba(0, 0, 0, 0.44);
}
.thxTo {
  font-size: 18px;
  font-weight: bold;
  line-height: 21.94px;
}
</style>
