<template>
  <v-container class="pa-0">
    <v-card tile elevation="0">
      <v-img
        eager
        lazy-src="@/assets/background.png"
        src="@/assets/background.png"
        class="white--text"
        aspect-ratio="1.6"
      >
        <v-container class="background" fill-height>
          <v-card width="100%" tile elevation="0" color="rgba(0, 0, 0, 0)">
            <v-card-text class="pa-0 px-3 thxTo white--text">
              Produk ini sudah diklaim oleh:
            </v-card-text>
            <v-card-text class="px-3">
              <v-card class="rounded-lg" color="rgba(255, 255, 255, 0.9)">
                <v-card-title class="pb-0 infoCust"> Nama: {{ listData.name }} </v-card-title>
                <v-card-title class="pb-0 infoCust"> Tanggal Beli: {{ listData.paid_date | moment("DD MMMM YYYY") }} </v-card-title>
                <v-card-title class="pb-0 infoCust"> Email: {{ listData.email }} </v-card-title>
                <v-card-title class="infoCust"> Telp: {{ listData.no_telp }} </v-card-title>
              </v-card>
            </v-card-text>
          </v-card>
        </v-container>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
    props: { listData: Object },
  data: () => ({
    absolute: true,
    opacity: 0.6,
  }),
};
</script>

<style scoped>
.background {
  background: rgba(0, 0, 0, 0.44);
}
.thxTo {
  font-size: 18px;
  font-weight: bold;
  line-height: 21.94px;
}
.infoCust{
    font-size: 15px;
    font-weight: normal;
    line-height: 18px;
}
</style>